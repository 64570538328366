import { tokensRaw } from '@vp/swan'

const SECTION = 'Category Section'

export const getMerchTileFromProductTile = (productTile: any, index: number): Partial<Tile> => {
  const imageUrl = productTile.productImage?.cloudinaryImage?.transformedCloudinaryUrl ?? ''
  return {
    section: SECTION,
    imageUrl,
    position: index,
    ctaUrl: productTile.transformedUrl?.url || productTile.url?.contentfulUrl || '',
    ctaCopy: productTile.productTileHeader,
    tilePricingConfiguration: null,
    id: productTile.contentful_id,
  }
}

export const getMerchTileFromMerchCategory = (merchCategory: any, index: number): any => {
  const imageUrl = merchCategory.category?.image.url ?? ''
  return {
    section: SECTION,
    imageUrl,
    position: index,
    ctaUrl: merchCategory.generatedUrl || '',
    ctaCopy: merchCategory.name.value,
    tilePricingConfiguration: null,
  }
}

export const carouselResponsiveSettings = [
  {
    breakpoint: tokensRaw.SwanBaseBreakpointXsStart,
    settings: {
      slidesToShow: 3.3,
      slidesToScroll: 2,
    },
  },
  {
    breakpoint: 768,
    settings: {
      slidesToShow: 7.25,
      slidesToScroll: 2,
    },
  },
  {
    breakpoint: 1023,
    settings: {
      slidesToShow: 6.25,
      slidesToScroll: 2,
    },
  },
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 6.25,
      slidesToScroll: 2,
    },
  },
  {
    breakpoint: 1293,
    settings: {
      slidesToShow: 7.25,
      slidesToScroll: 2,
    },
  },
  {
    breakpoint: 1296,
    settings: {
      slidesToShow: 8.25,
      slidesToScroll: 2,
    },
  },
  {
    breakpoint: 1439,
    settings: {
      slidesToShow: 8.25,
      slidesToScroll: 2,
    },
  },
  {
    breakpoint: 1440,
    settings: {
      slidesToShow: 9.25,
      slidesToScroll: 2,
    },
  },
  {
    breakpoint: 1703,
    settings: {
      slidesToShow: 9.25,
      slidesToScroll: 2,
    },
  },
  {
    breakpoint: 1704,
    settings: {
      slidesToShow: 10.25,
      slidesToScroll: 2,
    },
  },
]
