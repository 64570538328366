import { getUrlIdForTracking } from '../getUrlIdForTracking'
import { ANALYTICS_METADATA } from '../analytics/constants'
import { newTileTrackingConfig, TrackingConfig, TrackingData, TrackingLinkConfig } from '../../types'

export const getNewTileTrackingConfig = (trackingData: any) => {
  const {
    destinationUrl,
    mpvId,
    tileHeader,
    productKey,
    position,
    sectionHeader,
    subsection,
    row,
    section,
    pageZone,
    sourcePagePath,
  } = trackingData
  const customZone = row ? `${section}_${sectionHeader}_Row${row}` : section
  const urlId = getUrlIdForTracking(destinationUrl)
  const ctaValue = urlId || tileHeader

  let body = {
    sourcePagePath,
    destinationUrl: destinationUrl || '',
    pageZone: pageZone || customZone,
    tileHeader: ctaValue,
    mpvId,
    productKey,
    subsection,
    position: position || 0,
  }
  return newTileTrackingOutput(body)
}

// ##### WILL ERASE THIS WHEN FULLY TRANSITIONED TO NEW TILES
export const getTrackingConfig = (trackingData: TrackingData) => {
  const { ctaCopy, ctaUrl, mpvId, productKey, position, sectionHeader, subsection, row, section, pageZone, sourcePagePath } = trackingData
  const customZone = row ? `${section}_${sectionHeader}_Row${row}` : section
  const urlId = getUrlIdForTracking(ctaUrl)
  const ctaValue = urlId || ctaCopy

  let body = {
    sourcePagePath,
    destinationPagePath: ctaUrl || '',
    pageZone: pageZone || customZone,
    ctaValue,
    mpvId,
    productKey,
    subsection,
    position: position || 0,
  }
  return trackingOutput(body)
}

// ##### WILL ERASE THIS WHEN FULLY TRANSITIONED TO NEW TILES
const trackingOutput = (ctaTrackingConfig: TrackingConfig): TrackingLinkConfig => {
  const { destinationPagePath, ctaValue, mpvId, productKey, subSection, sourcePagePath, position, pageZone } = ctaTrackingConfig
  const { experimentId, experimentKey, variationId, variationKey } = ctaTrackingConfig
  const parsedDestinationPagePath = removeQueryParamsFrom(destinationPagePath)
  const composedCtaValue = subSection ? `${subSection}:${ctaValue}` : ctaValue
  const eventDetail = `${sourcePagePath};${parsedDestinationPagePath};${pageZone};${composedCtaValue}`
  const navigationDetail = `${composedCtaValue}:${position}`

  const eventName = 'Navigation Clicked'
  const experimentProperties =
    experimentId && variationId
      ? {
          experimentId: experimentId.toString(),
          experimentName: experimentKey,
          variationId: variationId.toString(),
          variationName: variationKey,
        }
      : {}

  return {
    eventName,
    properties: {
      category: ANALYTICS_METADATA.pageName,
      label: `Content:${pageZone}`,
      eventDetail,
      navigationDetail: pageZone === 'Hero' ? ctaValue : navigationDetail,
      product_id: mpvId,
      core_product_id: productKey,
      ...experimentProperties,
    },
    linkAttributes: {
      'data-section': `Content:${pageZone}`,
      'data-position': position,
      'data-translation': composedCtaValue,
    },
  }
}

const newTileTrackingOutput = (ctaTrackingConfig: newTileTrackingConfig): TrackingLinkConfig => {
  const { destinationUrl, tileHeader, mpvId, productKey, subSection, sourcePagePath, position, pageZone } = ctaTrackingConfig
  const { experimentId, experimentKey, variationId, variationKey } = ctaTrackingConfig
  const parsedDestinationPagePath = removeQueryParamsFrom(destinationUrl)
  const composedCtaValue = subSection ? `${subSection}:${tileHeader}` : tileHeader
  const eventDetail = `${sourcePagePath};${parsedDestinationPagePath};${pageZone};${composedCtaValue}`
  const navigationDetail = `${composedCtaValue}:${position}`

  const eventName = 'Navigation Clicked'
  const experimentProperties =
    experimentId && variationId
      ? {
          experimentId: experimentId.toString(),
          experimentName: experimentKey,
          variationId: variationId.toString(),
          variationName: variationKey,
        }
      : {}

  return {
    eventName,
    properties: {
      category: 'Home Page',
      label: `Content:${pageZone}`,
      eventDetail,
      navigationDetail: pageZone === 'Hero' ? tileHeader : navigationDetail,
      product_id: mpvId,
      core_product_id: productKey,
      ...experimentProperties,
    },
    linkAttributes: {
      'data-section': `Content:${pageZone}`,
      'data-position': position,
      'data-translation': composedCtaValue,
    },
  }
}

export const getSeoZoneCtaTrackingConfig = (
  sourcePagePath: string,
  destinationPagePath: string,
  ctaValue: string,
  position: number
): TrackingLinkConfig => {
  const pageZone = 'SEO Zone'
  const subSection = undefined

  return trackingOutput({ sourcePagePath, destinationPagePath, pageZone, ctaValue, subSection, position })
}

export const getTrackingConfigLinkAttributes = (trackingData: TrackingData) => {
  return getTrackingConfig(trackingData).linkAttributes || {}
}

export const removeQueryParamsFrom = (pagePath: string): string => {
  if (typeof window !== 'undefined') {
    return new URL(pagePath, window.location.origin).pathname
  }

  return pagePath
}
