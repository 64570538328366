import React, { useEffect, useState } from 'react'
import {
  BasicResponsiveImage,
  StandardTile,
  StandardTileContents,
  StandardTileName,
  StandardTileImage,
  StandardTileDescription,
  Link,
  Typography,
  Callout,
  StandardTileOverlay,
} from '@vp/swan'
import { TileWrapper } from '@vp/tile-wrapper'
import { CampaignCallout } from '@vp/campaign-callouts'
import { ImageLoadingStrategy, IPageContext, Tile, TileSkin } from '../types'
import { getTrackingDataNewTile } from '../helpers'
import { createOnClickTrackingHandler } from '../utils/analytics/trackingUtils'
import { getNewTileTrackingConfig, getTrackingConfig, getTrackingConfigLinkAttributes } from '../utils/analytics/trackingConfig'
import { useTrackingContext } from '../hooks/useTrackingContext'

export type GenericTileProps = {
  tile: Tile
  tileSkin: TileSkin
  imageLoadingStrategy: ImageLoadingStrategy
  pageInfo: IPageContext
  locale: string
  enableCampaignCallouts?: boolean
}

export const GenericTile = ({ tile, imageLoadingStrategy, locale, enableCampaignCallouts }: GenericTileProps) => {
  const { sourcePagePath } = useTrackingContext()

  const [renderCampaignCallouts, setRenderCampaignCallouts] = useState(false)
  useEffect(() => {
    setRenderCampaignCallouts(true)
  }, [])

  if (tile.useNewTiles && tile.template) {
    const trackingMethod = (event: React.MouseEvent<Element, MouseEvent>) => {
      const trackingData = getTrackingDataNewTile(tile)
      if (!trackingData) return

      const trackingAndNavigation = createOnClickTrackingHandler(
        getNewTileTrackingConfig(trackingData),
        tile.template?.tileDetails?.link?.href
      )
      if (trackingAndNavigation) {
        trackingAndNavigation(event)
      }
    }

    return (
      <TileWrapper
        tileProps={tile.template}
        locale={locale}
        clickHandler={trackingMethod}
        customTileProps={{ skin: 'circular' }}
        extraProps={{
          primaryLinkConfig: { skin: 'unstyled' },
        }}
        hideDescription={true}
      />
    )
  } else {
    const trackingData = getTrackingData({ tile, sourcePagePath })
    const { imageUrl, ctaCopy, ctaUrl, productTileLongDescription, mpv, calloutText, calloutColor, imageAltText } = tile
    const onClickTrackingHandler = createOnClickTrackingHandler(getTrackingConfig(trackingData), ctaUrl)
    return (
      <>
        <StandardTile skin='circular' {...getTrackingConfigLinkAttributes(trackingData)}>
          <StandardTileOverlay>
            {calloutText && calloutColor && (
              <Callout skin={calloutColor} variant={getVariant(calloutColor)}>
                {calloutText}
              </Callout>
            )}
            {renderCampaignCallouts && enableCampaignCallouts && mpv?.mpvId && <CampaignCallout product={mpv.mpvId} />}
          </StandardTileOverlay>
          <StandardTileImage>
            <BasicResponsiveImage aspectRatio={1} src={imageUrl} alt={imageAltText} loading={imageLoadingStrategy} />
          </StandardTileImage>
          <StandardTileContents>
            <Link href={ctaUrl} skin='unstyled' onClick={onClickTrackingHandler} covering>
              <StandardTileName>
                <Typography fontWeight='bold' fontSize='standard'>
                  {ctaCopy}
                </Typography>
              </StandardTileName>
            </Link>

            {productTileLongDescription && <StandardTileDescription>{productTileLongDescription}</StandardTileDescription>}
          </StandardTileContents>
        </StandardTile>
      </>
    )
  }
}

// ##### WILL ERASE THIS WHEN FULLY TRANSITIONED TO NEW TILES
function getTrackingData ({ tile, sourcePagePath }: { tile: Tile; sourcePagePath: string }) {
  const { ctaCopy, ctaUrl, sectionHeader, position, row, section, mpv } = tile
  const mpvTrackingData = mpv ? { mpvId: mpv.mpvId, productKey: mpv.productKey } : {}
  const trackingData = { ctaCopy, sourcePagePath, ctaUrl, position, sectionHeader, row, section, ...mpvTrackingData }
  return trackingData
}

const getVariant = (calloutColor: string) => {
  switch (calloutColor) {
    case 'standard':
    case 'new':
    case 'error': {
      return 'standard'
    }
    default:
      return 'inverse'
  }
}
