import { getTracking } from '@vp/tracking'
import { TrackingData, TrackingLinkConfig } from '../../types'
import { getTrackingConfig } from './trackingConfig'

type LinkClickHandler = (event: React.MouseEvent<Element, MouseEvent>) => void

const DELAY_BEFORE_NAVIGATING_IN_MS = 300

export const createOnClickTrackingHandler = (
  trackingConfig: TrackingLinkConfig | undefined,
  href?: string,
  openLinkNewTab?: boolean,
  callback?: () => void
): LinkClickHandler | undefined => {
  if (!trackingConfig) {
    return undefined
  }
  return event => {
    event.stopPropagation()
    stopNavigation(event)
    track(trackingConfig)

    if (href) {
      setTimeout(() => navigate(href, openLinkNewTab), DELAY_BEFORE_NAVIGATING_IN_MS)
    }

    if (callback) {
      setTimeout(callback, DELAY_BEFORE_NAVIGATING_IN_MS)
    }
  }
}

const track = (trackingConfig: any) => {
  const { eventName, properties } = trackingConfig
  const tracking = getTracking()

  if (tracking) {
    tracking.track(eventName, properties, {})
  } else {
    const options: AddEventListenerOptions = { once: true }
    window.addEventListener(
      'trackingReady',
      () => {
        const newTracking = getTracking()
        newTracking?.track(eventName, properties, {})
      },
      options
    )
  }
}

const navigate = (href: string, openLinkNewTab?: boolean): void => {
  if (!openLinkNewTab) {
    window.location.href = href
  } else {
    window.open(href, '_blank')
  }
}
const stopNavigation = (event: React.MouseEvent<Element, MouseEvent>): void => event.preventDefault()

export const getTrackingConfigLinkAttributes = (trackingData: TrackingData) => {
  return getTrackingConfig(trackingData).linkAttributes || {}
}
