import React from 'react'
import { Column, FlexBox, H2, Row } from '@vp/swan'

export const GenericTileSectionHeader = ({ title }: any) => {
  return (
    <Row>
      <Column
        span={12}
      >
        <FlexBox alignItems='baseline' flexWrap='wrap' mb='6'>
          <H2 fontSize='x2large' mb='0'>
            {title}
          </H2>
        </FlexBox>
      </Column>
    </Row>
  )
}
