import React from 'react'
import {
  ScreenClassProvider,
  SWAN_STYLE_KEY_MAP
} from '@vp/swan'
import { useSwanStyleKeys, useUserContextTracker } from '@vp/ubik-context'
import { ContentfulContextProvider, DebugSection } from '@vp/mcs-react-tools'
import { CampaignProvider } from '@vp/campaign-callouts'
import { ProductCategoriesContainer } from './components/ProductCategoriesContainer'
import { CategoriesRowProps } from './types'
import { TrackingProvider } from './hooks/useTrackingContext'
// import getMpvIdsUsed from "./utils/getMpvIdsUsed"

export const Fragment = (props: CategoriesRowProps) => {
  useSwanStyleKeys([
    SWAN_STYLE_KEY_MAP.core,
    SWAN_STYLE_KEY_MAP.grid,
    SWAN_STYLE_KEY_MAP.carousel,
    SWAN_STYLE_KEY_MAP.standardTile,
    SWAN_STYLE_KEY_MAP.utility,
  ])
  const data = {
    pageData: props.pageData,
    merchandisingCampaign: props.merchandisingCampaign,
    useNewTiles: props.pageData.useNewTiles,
    localizations: {
      nextSlide: 'Go to next slide',
      previousSlide: 'Go to previous slide',
    },
    defaultLocalizations: {
      nextSlide: 'Default value for Go to next slide',
      previousSlide: 'Default value for Go to previous slide',
    },
    locale: props.locale,
    tenant: props.tenant,
    debugData: {
      experimentKey: props.experimentKey,
      variationKey: props.variationKey,
      decision: props.decision,
      contentfulMetadata: props.contentfulMetadata,
    },
  }

  const search = typeof window !== "undefined" ? window.location.search : ""

  trackUsedContext(props)

  return (
    <ContentfulContextProvider space={props.contentfulMetadata.spaceId}>
      <TrackingProvider canonicalUrl='https://www.vistaprint.com'>
      <DebugSection
              contentfulMetadata={props.contentfulMetadata }
              availability={data.debugData}
              search={search}
            />
          <CampaignProvider tenant={props.tenant} locale={props.locale}>
            <ScreenClassProvider>
              <ProductCategoriesContainer data={data} />
            </ScreenClassProvider>
          </CampaignProvider>
      </TrackingProvider>
    </ContentfulContextProvider>
  )
}

function trackUsedContext (props: any) {
  const tracker = useUserContextTracker()
  if (props.experimentKey) {
    tracker({
      type: 'experiment',
      name: props.experimentKey,
      variation: props.variationKey
    })
  }
  if (props.decision) {
    tracker({
      type: 'treatment',
      name: props.decision
    })
  }
  if (props.merchandisingCampaign) {
    tracker({
      type: 'campaign',
      name: props.merchandisingCampaign
    })
  }
}