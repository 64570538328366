import { TileWrapperProps } from './types'

export const getTrackingDataNewTile = (tile: TileWrapperProps) => {
  const { position, row, section, sectionHeader } = tile
  const tileHeader = tile.template?.tileDetails?.header?.value ?? ''
  const destinationUrl = tile.template?.tileDetails?.link?.href ?? ''

  const trackingData = {
    tileHeader,
    destinationUrl,
    position,
    row,
    section,
    sectionHeader,
    productKey: tile.template?.productKey ?? '',
    sourcePagePath: '/',
  }
  return trackingData
}
